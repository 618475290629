import React, { useEffect } from 'react'
import { PageProps, navigate } from 'gatsby'
import { logout } from '../services/auth'

export default function LogoutPage(props: PageProps) {
  useEffect(() => {
    logout()
    navigate('/')
  })

  return <>null</>
}
